import React from "react"
import { PageHero } from "../components/hero/PageHero"
import { Section } from "../layout/surfaces/Section"
import { Container } from "../layout/surfaces/Container"
import { Heading } from "../components/typogarphy/Heading"
import { Paragraph } from "../components/typogarphy/Paragraph"
import { TextList, TextListItem } from "../components/lists/TextList"

const PrivacyPage = () => {
  return (
    <>
      <PageHero type="services" />
      <Section>
        <Container>
          <Heading variant="h2" size="h3">
            Privacy Policy
          </Heading>
          <Paragraph>
            Deyst Solutions is committed to protecting your privacy and giving
            you a safe online experience. This Privacy Policy applies to all of
            the products, services and websites offered by Deyst Solutions or
            its subsidiaries or affiliated companies. Sometimes, we may post
            product specific privacy notices or materials to explain our
            services and products in more detail. If you have any questions
            about this Privacy Policy, please feel free to contact us through
            our website.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Advertising Cookie and OPT-OUT
          </Heading>
          <Paragraph>
            When you are accessing the internet through a web browser, we may
            use cookies from Facebook, Google (and other advertisers) to improve
            your online experience, including the display of more relevant ads.
            Anyone may opt-out of the Google cookie at any time by going to your
            Google Ad Settings. You may also opt-out of the Facebook cookie at
            any time by going to your Facebook Ad Settings.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Information We Collect and How We Use It
          </Heading>
          <Paragraph>
            We may collect the following types of information:
          </Paragraph>
          <Heading variant="h3" size="h4">
            Information you provide
          </Heading>
          <Paragraph>
            When you sign up with or make a purchase from Deyst Solutions, we
            ask you for personal information. We may combine the information you
            submit with information from other Deyst Solutions services or third
            parties in order to provide you with a better experience and to
            improve the quality of our services. This won&#39;t occur without
            your consent unless necessary for site security. This data is
            retained for as long as you have an active account with Deyst
            Solutions.
          </Paragraph>
          <Heading variant="h3" size="h4">
            Cookies
          </Heading>
          <Paragraph>
            When you visit Deyst Solutions, we send one or more cookies to your
            device. We use cookies to improve the quality of our service,
            including for storing user preferences, improving search results and
            ad selection, and tracking user trends, such as how people search.
            Deyst Solutions also uses cookies in its advertising services to
            help advertisers and publishers serve and manage ads across the web
            and on Deyst Solutions services.
          </Paragraph>
          <Heading variant="h3" size="h4">
            Log Information
          </Heading>
          <Paragraph>
            When you access the services of Deyst Solutions via browser,
            application, or other clients, our servers automatically record
            certain information. These server logs may include information such
            as your web request, your interaction with a service, Internet
            Protocol address, browser type, browser language, the date and time
            of your request and one or more cookies that may uniquely identify
            your browser or your account. These logs are retained for a period
            of &lt;NUMBER&gt; days and are used for anti-fraud and website
            security purposes. After this time, these logs are automatically
            deleted.
          </Paragraph>
          <Heading variant="h3" size="h4">
            Traffic Information
          </Heading>
          <Paragraph>
            When you access the services of Deyst Solutions via browser,
            application, or other, clients we record certain information using
            our traffic analysis tool, Google Analytics. This information is
            stored in an anonymised manner but does include data such as your
            web request, your interaction with a service, Internet Protocol
            address, browser type, browser language, the date and time of your
            request and one or more cookies that may uniquely identify your
            browser or your account. These logs are retained for a period of
            &lt;NUMBER&gt; months and are used for content and marketing
            planning purposes so that we can improve our content and services.
            After this time, these logs are automatically deleted.
          </Paragraph>
          <Heading variant="h3" size="h4">
            User Communications
          </Heading>
          <Paragraph>
            When you send an email or other communications to Deyst Solutions,
            we may retain those communications in order to process your
            inquiries, respond to your requests and improve our services. When
            you send and receive SMS messages to or from one of our services
            that provides SMS functionality, we may collect and maintain
            information associated with those messages, such as the phone
            number, the wireless carrier associated with the phone number, the
            content of the message, and the date and time of the transaction. We
            may use your email address to communicate with you about our
            services or products but never to send you communications that are
            not related to your purchases or that you haven&#39;t opted into.
          </Paragraph>
          <Heading variant="h3" size="h4">
            Affiliated Deyst Solutions services on other sites
          </Heading>
          <Paragraph>
            We offer some of our services on or through other websites. Personal
            information that you provide to those sites may be sent to Deyst
            Solutions in order to deliver the service. We process such
            information under this Privacy Policy.
          </Paragraph>
          <Heading variant="h3" size="h4">
            Third Party Applications
          </Heading>
          <Paragraph>
            Deyst Solutions may make available third-party applications, such as
            plugins, modules or extensions, through its services. The
            information collected by Deyst Solutions when you enable a
            third-party application is processed under this Privacy Policy.
            Information collected by the third-party application provider is
            governed by their privacy policies.
          </Paragraph>
          <Heading variant="h3" size="h4">
            Location data
          </Heading>
          <Paragraph>
            Deyst Solutions offers location-enabled services. If you use those
            services, Deyst Solutions may receive information about your actual
            location (such as GPS signals sent by a mobile device) or
            information that can be used to approximate a location (such as a
            cell ID). This data is not recorded without your permission and is
            not held for any longer than the period required to deliver the
            specific service.
          </Paragraph>
          <Heading variant="h3" size="h4">
            Other Sites
          </Heading>
          <Paragraph>
            This Privacy Policy applies to Deyst Solutions&#39; services only.
            We do not exercise control over the sites displayed as search
            results, sites that include Deyst Solutions applications, products
            or services, or links from within our various services. These other
            sites may place their own cookies or other files on your computer,
            collect data or solicit personal information from you.
          </Paragraph>
          <Paragraph>
            In addition to the above, we may use the information we collect to:
          </Paragraph>
          <TextList>
            <TextListItem>
              Provide, maintain, protect, and improve our services (including
              advertising services) and develop new services; and
            </TextListItem>
            <TextListItem>
              Protect the rights or property of Deyst Solutions or our users.
            </TextListItem>
          </TextList>
          <Paragraph>
            If we use this information in a manner different than the purpose
            for which it was collected, then we will ask for your consent prior
            to such use.
          </Paragraph>
          <Paragraph>
            Deyst Solutions processes personal information on our servers in
            Australia. In some cases, we process personal information outside
            your own country.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Choices
          </Heading>
          <Paragraph>
            You can use your account on the Deyst Solutions website to review
            and control the information stored in your Deyst Solutions account.
            Most browsers are initially set up to accept cookies, but you can
            reset your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some of the Deyst Solutions features
            and services may not function properly if your cookies are disabled.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Information Sharing
          </Heading>
          <Paragraph>
            Deyst Solutions only shares personal information with other
            companies or individuals outside of Deyst Solutions in the following
            limited circumstances:
          </Paragraph>
          <TextList>
            <TextListItem>
              We have your consent. We require opt-in consent for the sharing of
              any sensitive personal information.
            </TextListItem>
            <TextListItem>
              If we provide such information to our subsidiaries, affiliated
              companies or other trusted businesses or persons for the purpose
              of processing personal information on our behalf. We require that
              these parties agree to process such information based on our
              instructions and in compliance with this Privacy Policy and any
              other appropriate confidentiality and security measures.
            </TextListItem>
            <TextListItem>
              We have a good faith belief that access, use, preservation or
              disclosure of such information is reasonably necessary to (a)
              satisfy any applicable law, regulation, legal process or
              enforceable governmental request, (b) enforce applicable Terms of
              Service, including investigation of potential violations thereof,
              (c) detect, prevent, or otherwise address fraud, security or
              technical issues, or (d) protect against harm to the rights,
              property or safety of Deyst Solutions, its users or the public as
              required or permitted by law.
            </TextListItem>
          </TextList>
          <Paragraph>
            If Deyst Solutions becomes involved in a merger, acquisition, or any
            form of sale of some or all of its assets, we will ensure the
            confidentiality of any personal information involved in such
            transactions and provide notice before personal information is
            transferred and becomes subject to a different privacy policy.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Information Security
          </Heading>
          <Paragraph>
            We take appropriate security measures to protect against
            unauthorized access to or unauthorized alteration, disclosure or
            destruction of data. These include internal reviews of our data
            collection, storage and processing practices and security measures,
            including appropriate encryption and physical security measures to
            guard against unauthorized access to systems where we store personal
            data.
          </Paragraph>
          <Paragraph>
            We restrict access to personal information to Deyst Solutions
            employees, contractors and agents who need to know that information
            in order to process it on our behalf. These individuals are bound by
            confidentiality obligations and may be subject to discipline,
            including termination and criminal prosecution if they fail to meet
            these obligations.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Accessing and Updating Personal Information
          </Heading>
          <Paragraph>
            When you use Deyst Solutions services, we make good faith efforts to
            provide you with access to your personal information and either to
            correct this data if it is inaccurate or to delete such data at your
            request if it is not otherwise required to be retained by law or for
            legitimate business purposes. We ask individual users to identify
            themselves and the information requested to be accessed, corrected
            or removed before processing such requests, and we may decline to
            process requests that are unreasonably repetitive or systematic,
            require disproportionate technical effort, jeopardize the privacy of
            others, or would be extremely impractical (for instance, requests
            concerning information residing on backup tapes), or for which
            access is not otherwise required. In any case, where we provide
            information access and correction, we perform this service free of
            charge, except if doing so would require a disproportionate effort.
            Because of the way we maintain certain services, after you delete
            your information, residual copies may take a period of time before
            they are deleted from our active servers and may also remain in our
            backup systems for a period of time.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Enforcement
          </Heading>
          <Paragraph>
            Deyst Solutions regularly reviews its compliance with this Privacy
            Policy. When we receive formal written complaints, it is Deyst
            Solutions&#39; policy to contact the complaining user regarding his
            or her concerns. We will cooperate with the appropriate regulatory
            authorities, including local data protection authorities, to resolve
            any complaints regarding the transfer of personal data that cannot
            be resolved between Deyst Solutions and an individual.
          </Paragraph>
          <Heading variant="h2" size="h3">
            Changes to This Privacy Policy
          </Heading>
          <Paragraph>
            Please note that this Privacy Policy may change from time to time.
            We will not reduce your rights under this Privacy Policy without
            your explicit consent. We will post any Privacy Policy changes on
            this page and, if the changes are significant, we will provide a
            more prominent notice (including, for certain services, email
            notification of Privacy Policy changes).
          </Paragraph>
        </Container>
      </Section>
    </>
  )
}

export default PrivacyPage

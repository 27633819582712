import React from "react"
import { list } from "./TextList.module.scss"

export const TextList = props => {
  return <ul className={list}>{props.children}</ul>
}

export const TextListItem = props => {
  return <li>{props.children}</li>
}

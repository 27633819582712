import React from "react"
import { Container } from "../../layout/surfaces/Container"
import { hero, box, about, contact, services } from "./PageHero.module.scss"

export const PageHero = ({ type }) => {
  const styles = { about, contact, services }

  return (
    <div className={hero}>
      <Container>
        <div className={`${box} ${styles[type]}`}></div>
      </Container>
    </div>
  )
}
